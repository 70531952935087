.heading h1 {
  font-family: 'Roboto';
  font-size: 24px;
  letter-spacing: -0.7;
  font-weight: bold;
  margin: 10px;
}

.heading p {
  font-family: 'Roboto';
  font-size: 14px;
  letter-spacing: -0.7;
  margin: 20px;
}

.heading img {
  position: absolute;
  margin-left: -480px;
  margin-top: -100px;
  height: 200px;
}

.flexContainer {
  padding: 0;
  margin: 0;
  background: white;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  height: 100%;
}

.flexColumn {
  padding: 0;
  margin: 0;
  flex-flow: column nowrap;
  display: flex;
  width: 40%;
}

.flexItem {
  display: flex;
}

.flexOutputGas {
  flex-flow: column nowrap;
  display: flex;
  justify-content: center;
  background: #eff0f8;
  font-size: 14px;
  padding: 8px 14px 8px;
  width: calc(100% - 15px);
  height: 100%;
}

.flexOutputGas:nth-of-type(2) {
  margin-top: 15px;
}

.flexOutputGas .result {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
}

.flexOutputGas .description {
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: thin;
}

.flexOutputDelta {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  flex-flow: column nowrap;
  display: flex;
  background: #c0ffe0;
  width: 60%;
}

.flexOutputDelta .result {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 38px;
  line-height: 1.2;
}

.flexOutputDelta .description {
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: thin;
}

@media screen and (min-width: 768px) {
  .flexOutputGas .description {
    font-size: 14px;
  }

  .flexOutputGas .result {
    font-size: 20px;
  }

  .flexOutputDelta .result {
    font-size: 58px;
  }

  .flexOutputDelta .description {
    font-size: 14px;
  }
}

.form {
  display: flex;
  flex-flow: column;
}

.input {
  font-family: 'Roboto Mono';
  font-size: 14px;
  padding-left: 24px;
  background: #dfe1f1;
  height: 50px;
  margin-top: 4px;
  border: hidden;
}

.presets input {
  border: 1px solid #eee;
}

.presets input {
  height: 50px;
  transition: all 400ms;
  margin: 5px;
  border-radius: 50%;
}

.presets input:hover {
  box-shadow: 0px 3px 10px 0px rgb(116, 110, 110, 0.5);
}

.presets input.active {
  box-shadow: 0px 3px 10px 0px rgb(116, 110, 110, 0.5) !important;
}

.usd {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.iconBounce {
  position: relative;
  animation: bounce 300ms infinite alternate;
}

@keyframes bounce {
  100% {
    transform: translateY(-5px);
  }
}
