@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500;700&display=swap');

::selection {
  background-color: #f01a37;
  color: #fff;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) *[role='button']:focus,
body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
  box-shadow: none;
}

body.user-is-tabbing button:focus,
body.user-is-tabbing a:focus,
body.user-is-tabbing input:focus,
body.user-is-tabbing select:focus,
body.user-is-tabbing textarea:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
}

html {
  font-size: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rainbowBg,
.rainbowText {
  background: linear-gradient(to right, #f01a37, #169fc9, #f01a37);
  background-size: 200% 200%;
  transition: all 0.3s ease-in-out !important;
}

.rainbowText {
  background-clip: text;
  -webkit-background-clip: text;
}

.rainbowText:hover {
  color: rgba(0, 0, 0, 0);
}

.rainbowBg:hover,
.rainbowText--constant,
.rainbowText:hover,
.rainbowText[aria-expanded='true'] {
  animation: rainbow 4s ease-in-out infinite;
  fill: linear-gradient(to right, #f01a37, #169fc9, #f01a37);
}

a svg {
  color: #f01a37 !important;
}

@keyframes rainbow {
  25% {
    background-position: top right;
  }
  50% {
    background-position: bottom left;
  }
  75% {
    background-position: bottom right;
  }
  100% {
    background-position: top left;
  }
}

.socialIcon {
  transform: scale(1.2);
}

svg .rainbowFill {
  fill: #f01a37;
  transition: fill 0.5s ease;
}

svg:hover .rainbowFill {
  fill: #cc142d;
}

// Popover component
.chakra-popover__content {
  width: unset !important;
  max-width: unset !important;
}

// Demos page
.redAccent--right::after,
.redAccent--left::after {
  content: '';
  width: 250px;
  height: 250px;
  position: absolute;
  top: -25%;
  background-repeat: no-repeat !important;
  background-size: cover;
  display: none;
}

.redAccent--right::after {
  right: -15%;
  transform: rotate(10deg);
}

.redAccent--left::after {
  left: -15%;
  transform: rotate(-10deg);
}

@media only screen and (min-width: 1200px) {
  .redAccent--right::after,
  .redAccent--left::after {
    display: block;
  }
}
