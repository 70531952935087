.burger {
  z-index: 2000;
  position: relative;
  outline: none;
  display: flex !important;
  align-items: center !important;
  padding: 0 !important;
}

.burger div {
  width: 30px;
  height: 30px;
}
