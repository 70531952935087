@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500;700&display=swap);
::selection{background-color:#f01a37;color:#fff}*{box-sizing:border-box}body{margin:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}body:not(.user-is-tabbing) button:focus,body:not(.user-is-tabbing) *[role='button']:focus,body:not(.user-is-tabbing) a:focus,body:not(.user-is-tabbing) input:focus,body:not(.user-is-tabbing) select:focus,body:not(.user-is-tabbing) textarea:focus{outline:none;box-shadow:none}body.user-is-tabbing button:focus,body.user-is-tabbing a:focus,body.user-is-tabbing input:focus,body.user-is-tabbing select:focus,body.user-is-tabbing textarea:focus{box-shadow:0 0 0 3px rgba(66,153,225,0.6)}html{font-size:100%}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}.rainbowBg,.rainbowText{background:linear-gradient(to right, #f01a37, #169fc9, #f01a37);background-size:200% 200%;transition:all 0.3s ease-in-out !important}.rainbowText{background-clip:text;-webkit-background-clip:text}.rainbowText:hover{color:rgba(0,0,0,0)}.rainbowBg:hover,.rainbowText--constant,.rainbowText:hover,.rainbowText[aria-expanded='true']{animation:rainbow 4s ease-in-out infinite;fill:linear-gradient(to right, #f01a37, #169fc9, #f01a37)}a svg{color:#f01a37 !important}@keyframes rainbow{25%{background-position:top right}50%{background-position:bottom left}75%{background-position:bottom right}100%{background-position:top left}}.socialIcon{transform:scale(1.2)}svg .rainbowFill{fill:#f01a37;transition:fill 0.5s ease}svg:hover .rainbowFill{fill:#cc142d}.chakra-popover__content{width:unset !important;max-width:unset !important}.redAccent--right::after,.redAccent--left::after{content:'';width:250px;height:250px;position:absolute;top:-25%;background-repeat:no-repeat !important;background-size:cover;display:none}.redAccent--right::after{right:-15%;transform:rotate(10deg)}.redAccent--left::after{left:-15%;transform:rotate(-10deg)}@media only screen and (min-width: 1200px){.redAccent--right::after,.redAccent--left::after{display:block}}

.Navigation_burger__1NdSq{z-index:2000;position:relative;outline:none;display:flex !important;align-items:center !important;padding:0 !important}.Navigation_burger__1NdSq div{width:30px;height:30px}

.Roadmap_roadMapTable__11-IM{max-width:700px;margin:0 auto;border-collapse:collapse}.Roadmap_roadMapTable__11-IM .Roadmap_old__1-a1r{background-color:#f2f4f7}.Roadmap_current__3Wj4T{background-color:white;height:65px;border-left:25px solid white;border-right:25px solid white;box-shadow:-8px 8px 45px rgba(0,0,0,0.18)}.Roadmap_new__vwVsr{background-color:transparent}.Roadmap_roadMapTable__11-IM td{height:50px;padding:10px 20px}.Roadmap_roadMapTable__11-IM td:nth-child(2){width:20px;color:#f01a37}.Roadmap_roadMapTable__11-IM td:last-child{padding-left:20px;padding-right:20px;text-align:left}td{line-height:normal}

.GasCalc_heading__1ueKD h1 {
  font-family: 'Roboto';
  font-size: 24px;
  letter-spacing: -0.7;
  font-weight: bold;
  margin: 10px;
}

.GasCalc_heading__1ueKD p {
  font-family: 'Roboto';
  font-size: 14px;
  letter-spacing: -0.7;
  margin: 20px;
}

.GasCalc_heading__1ueKD img {
  position: absolute;
  margin-left: -480px;
  margin-top: -100px;
  height: 200px;
}

.GasCalc_flexContainer__1yUVi {
  padding: 0;
  margin: 0;
  background: white;
  -ms-box-orient: horizontal;
  display: -moz-flex;
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  height: 100%;
}

.GasCalc_flexColumn__2Na6T {
  padding: 0;
  margin: 0;
  flex-flow: column nowrap;
  display: flex;
  width: 40%;
}

.GasCalc_flexItem__Sv_Dm {
  display: flex;
}

.GasCalc_flexOutputGas__1d2zc {
  flex-flow: column nowrap;
  display: flex;
  justify-content: center;
  background: #eff0f8;
  font-size: 14px;
  padding: 8px 14px 8px;
  width: calc(100% - 15px);
  height: 100%;
}

.GasCalc_flexOutputGas__1d2zc:nth-of-type(2) {
  margin-top: 15px;
}

.GasCalc_flexOutputGas__1d2zc .GasCalc_result__2WpNc {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
}

.GasCalc_flexOutputGas__1d2zc .GasCalc_description__2HL-9 {
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: thin;
}

.GasCalc_flexOutputDelta__JIa7N {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  flex-flow: column nowrap;
  display: flex;
  background: #c0ffe0;
  width: 60%;
}

.GasCalc_flexOutputDelta__JIa7N .GasCalc_result__2WpNc {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 38px;
  line-height: 1.2;
}

.GasCalc_flexOutputDelta__JIa7N .GasCalc_description__2HL-9 {
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: thin;
}

@media screen and (min-width: 768px) {
  .GasCalc_flexOutputGas__1d2zc .GasCalc_description__2HL-9 {
    font-size: 14px;
  }

  .GasCalc_flexOutputGas__1d2zc .GasCalc_result__2WpNc {
    font-size: 20px;
  }

  .GasCalc_flexOutputDelta__JIa7N .GasCalc_result__2WpNc {
    font-size: 58px;
  }

  .GasCalc_flexOutputDelta__JIa7N .GasCalc_description__2HL-9 {
    font-size: 14px;
  }
}

.GasCalc_form__1J7Uz {
  display: flex;
  flex-flow: column;
}

.GasCalc_input__XqJ3M {
  font-family: 'Roboto Mono';
  font-size: 14px;
  padding-left: 24px;
  background: #dfe1f1;
  height: 50px;
  margin-top: 4px;
  border: hidden;
}

.GasCalc_presets__ufoVW input {
  border: 1px solid #eee;
}

.GasCalc_presets__ufoVW input {
  height: 50px;
  transition: all 400ms;
  margin: 5px;
  border-radius: 50%;
}

.GasCalc_presets__ufoVW input:hover {
  box-shadow: 0px 3px 10px 0px rgb(116, 110, 110, 0.5);
}

.GasCalc_presets__ufoVW input.GasCalc_active__3lz70 {
  box-shadow: 0px 3px 10px 0px rgb(116, 110, 110, 0.5) !important;
}

.GasCalc_usd__2b028 {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.GasCalc_iconBounce__1Rtzc {
  position: relative;
  animation: GasCalc_bounce__1Bhhz 300ms infinite alternate;
}

@keyframes GasCalc_bounce__1Bhhz {
  100% {
    transform: translateY(-5px);
  }
}

